// BOOTSTRAP
// $primary: #f408dc;
// $secondary: rgb(145, 145, 145);
$primary: #4e90b2;
$secondary: #373230;
$success: rgb(34, 34, 34);
$warning: #ebaa05;
$danger: #ef1d3c;
$light: #f5f5f5;

// OTHERS
// Back-Ground color
$bg-color: #ffffff;

// Other Pallet Colors
// $pallet2: #f408dc;
$pallet2: #373230;


// Main Menu related
$menu-underline-color: #be2222;
$menu-color: #3c57a6;

// button-colors
$secondary-btn-color: #f2f2f2;
$secondary-button-text: rgb(192, 6, 6);

// carousel
$carousel-heading: rgb(72, 149, 159);
$carousel-bg-color: #e9f0e8;

// star rating
$unchecked-star:#f7e09c;
$checked-star:#fdb915;

// client-slogan
$slogan-color:#000;

// menu heading
$mh-bg-color:#000000bf;
$mh-color:#fff;
$md-border:#cdcdcd;
$menu-txt-color:rgb(34, 34, 34);

// product listing
$product-listing-heading-color:#777;

// others
$white:#ffffff;
$black:#000;
$light-ash:#777;
$red:red;

// buttons
$button-radius:20px;

// product-card
$product-cd:"earthyworthy";

// signup in footer
$signup-ft-bg: #ede8ca;

// footer backgroun
$footer-bg:rgb(34, 34, 34);

// info card
$info-card:#c7ce9d;
$info-bg:#EDE8CA;

// forms
$form-header:rgb(34, 34, 34);
$form-label-color: #333333;

// checkout
$checkout-tab-color:rgb(34, 34, 34);

// fonts
$theme-font: 'proxima-regular','sans-serif';

// ABOUT US
$aboutus_bg:#ffffff;
$aboutus_heading: cadetblue;
