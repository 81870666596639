@media (min-width: 1300px) {
	.container {
	  max-width: 1280px;
	}

  }
  @media (min-width: 1360px) {
	.header-special{
		margin-top: 3%
	}
  }
  @media (max-width: 1350px) {
	.header-special{
		margin-top: 4%
	}
  }
  /*@media (min-width: 992px) {
	  .navbar-toggler {
		  display: none;
	  }
	  .btn.close-btn {
		  display: none;
	  }
	  
  }*/
  @media only screen and (max-width:1920px) and (min-width:1250px)  {

	.detail-align{
		margin-top: 15px !important;
	  }

	  .con-hig{
		height: 685px;
	  }
  }

  
  .new_miles {
    width: 100%;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    padding: 15px 5px;
    box-shadow: 0 5px 6px 1px rgba(0, 0, 0, 0.2);
    margin-bottom: 25px;
    min-height: 210px;
    position: relative;
    background: linear-gradient(to bottom, #d8d8d8, #e8e9ee);
  }
  .new_miles p{
  text-align: center;
    font-size: 16.5px;
    line-height: 1.2;
  }
  .miles_icon img{
	width: 50px;

  }
  .main_about_left img{  width: 100%;
    height: auto;
}
.main_about_right h2 {
	font-size: 35px;
    margin-top: 5px;
	font-weight: 700;
    color: #000;
}
.main_about_right h4 {
	font-size: 21px;
    margin-top: 10px;
	font-weight: 700;
    color: #000;
}
.main_about_right p {
font-size: 20px;
margin-top: 10px;
text-align: justify;
line-height: 1.6;
}
.miles_icon{
  text-align: center;
    margin-bottom: 10px;
  }
  .new_miles h4 {
    text-align: center;
    margin: 4px 0px;
    position: absolute;
    bottom: 2%;
    left: 30%;
    z-index: 9;
    color: #fff;
    font-weight: 500;
    font-size: 27px;
  }
  .new_miles::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 45px;
    left: 0px;
    bottom: 0px;
    background: #2f8df1;
    box-shadow: 0 5px 6px 1px rgba(0, 0, 0, 0.2);
}
  .main_miless {
    background-image: url(http://www.specialwires.in/wp-content/themes/specialwire/images/miless.jpg);
    overflow: hidden;
    position: relative;
    padding: 60px 0px;
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  @media (min-width: 991px) {
	.trers-grid-image {
	  img {
		max-width: 150px;
	  }
	}
  }
  
  @media (max-width: 1199px) {
	footer{
        .footer-links {
            display: block;
        }
    }
	.free-shipping-section {
	  ul {
		flex-flow: wrap;
		li {
		  margin-bottom: 15px;
		}
	  }
	}
	body {
	//   padding-top: 47px;
	  padding-top: 0px;
	}
  
	.header {
	  .flex-container {
		.header-row-one {
		  display: block;
		  .account-section {
			position: absolute;
			top: 0;
			width: 100%;
			border-bottom: 1px solid #00000012;
			left: 0;
			z-index: 99;
			background: #fff;
			.account {
			  justify-content: flex-end;
    		 margin-top: 10px;
			}
		  }
		}
	  }
	}
	.dropdown-content {
		display: none;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 100%;
		box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
		z-index: 1;
	  }
	  
	  .dropdown-content a {
		float: none;
		color: black;
		padding: 12px 16px;
		text-decoration: none;
		display: block;
		text-align: left;
	  }
	  
	  .dropdown-content a:hover {
		background-color: #ddd;
	  }
	  
	  .dropdown:hover .dropdown-content {
		display: block;
	  }
  }
  @media (max-width: 991px) {

	.product-details-wrapper {
		.slider-nav {
			bottom:6px;
			
		}
		.details-wrapper {
			
			.product-all-details {
				.row {
					.col-left {
						flex: auto;
					}
				}
				.product-addtional-details{
					padding-bottom:15px;
					&:before {
						width:100%;
					}
				}
			}
		}
	}
	.banner-content {
		.content {
		  h2, h1 {
			font-size: 30px;
		  }
		}
	  }
	/**Product**/
	.product-box-inner {
	  .product-image {
		max-width: 150px;
	  }
	  .product-detail {
		.price-wrapper {
		  position: inherit;
		  transform: none;
		}
		.add-to {
		  position: inherit;
		}
	  }
	  .verified {
		top: 10px;
	  }
	}
  
	.best-products {
	  .row-fluid {
		display: block;
		.tyers-grid {
		  justify-content: space-between;
		  li {
			display: inline-block;
			margin: 15px 0px 15px 0px;
			text-align: center;
		  }
		}
	  }
	  .column {
		&.single-image {
		  margin-top: 15px;
		}
	  }
	}
  }
  
  @media (max-width: 767px) {
	.mob-filter{
		
		z-index: 200;
		background-color: $bg-color;
		width: 100%;
		top: 0;
    	bottom:0;
		left:0;
    	position:fixed;
    	overflow-y:scroll;
    	overflow-x:hidden;
	}

	.filter-button{
		display: block !important;
		position: fixed;
    	bottom: 4%;
    	left: 45%;
   		width: 50%;
    	height: 8%;
		border-radius: 50px;
		border: 2px solid $primary;
		font-size: 18px;
		font-weight: 600;
		-webkit-box-shadow: 0px 0px 33px -10px rgba(0,0,0,0.59);
		-moz-box-shadow: 0px 0px 33px -10px rgba(0,0,0,0.59);
		box-shadow: 0px 0px 33px -10px rgba(0,0,0,0.59);
	}


	.details-wrapper {
		padding-right: 0px !important;
    	padding-left: 0px !important;
    	margin-right: 0px !important;
    	margin-left: 0px !important;
		.slider-nav {
			bottom:6px;
			max-width: 240px;
			min-width: 230px;
		}
	}
	.banner-content {
		.content {
		  h2, h1 {
			font-size: 20px;
		  }
		}
	  }
	.aside-group {
	  display: block;
	}
  
	/**Title**/
	.content-title {
	  .title-box {
		.title {
		  &:after {
			display: none;
		  }
		}
	  }
	}
  
	.header {
	  .flex-container {
		display: block;
		.logo {
		  text-align: center;
		//   padding: 15px;
		  margin:0px auto;
		}
		.header-row-one {
			.search-bar-group {
				.search-bar {
					margin-top:10px;
					margin-right: -4rem;
					width:100% !important;
					
				}
			}
		}
	  }
	}
  
	.content-1-section {
	  position: static;
	  border-radius: 0px;
	}
	.homepage-banner-slider {
	  .owl-theme {
		.owl-dots {
		  bottom: 0;
		}
	  }
	}
  
	.best-products {
	  .column {
		.tyers-grid {
		  li {
			display: block;
			text-align: center;
		  }
		}
	  }
	}
  }
  @media (max-width: 640px) {
.special-trash{
	left: 157% !important;
}
	.checkout-btn{
		min-width:fit-content; 
		padding-left:0px !important; 
		height:30px !important;
		position: relative;
		top: 0px;
		width: 100px !important;
		margin-top: 16%;
	}
	
	.collection-wrapper {
	  .col-grid-box {
		padding: 0px 10px 0px 10px;
	  }
	}
	.vistas-footmenu{
		display: block;
	    width: 100%;
	}
	footer{
        .footer-links {
            display: grid !important;
        }
		.footer-col{
			justify-content: center !important;
			padding: 5px;
			margin:5px;
			min-width:100%;
			max-width: 100% !important;
		}
    }

	.banner-content {
	  .content {
		h2, h1 {
		  font-size: 18px;
		}
	  }
	}
	.header {
	  .flex-container {
		.header-row-one {
		  .search-bar {
			margin-left: 0px;
		  }
		  .account-section{
			position: fixed !important;
			z-index: 2 !important;
			padding-right:0px;
			.account{
				margin-right: 10px !important;
			}
			.dropdown-toggle{
				font-size:10px !important;
			}
			.fa-user{
				font-size:10px !important
			}
		  }
		}
	  }
	}


	.product-box-inner {
	  display: block;
	  padding: 10px 0px 10px 0;
	  .img-wrapper {
		padding-bottom: 10px;
		.product-image {
		  max-width: 100%;
		  width: 100%;
		}
	  }
  
	  .product-detail {
		padding-left: 0px;
	  }
	  .verified {
		top: 10px;
		transform: none;
	  }
	}
  
	/*Pagenation**/
	.product-pagination {
	  .row {
		display: block;
		> div {
		  margin-bottom: 10px;
		  text-align: center;
		  p {
			margin-bottom: 0px;
		  }
		  .pagination {
			justify-content: center;
		  }
		}
	  }
	}
	.mobile-filter{
		display: block !important;
	  }
	.desktop-filter{
		display : none !important;
	}
	.mob-categories{
		
		opacity: 1;
		padding:10px;
		font-family: 'proxima-regular','sans-serif'!important;
		position: fixed;
		background-color: white;
		z-index: 100;
		width: 100%;
		height: 100%;
		border-right: 50px solid rgba(0, 0, 0, 0.5);
		overflow-y: auto;
		top:0;
		left:0;
		background-clip: padding-box;
		
		
	}
	.touch-out{
		 background-color:transparent;
		 position: fixed;
		 z-index: 999;
		 width: 60px;
		 right: 0px;
		 height:300%;
		 top:0px;
		 overflow:hidden;
		
	}
	// body{
	// 	position: fixed !important;
	// }

  }
  /*** Range **/
  
  @media (max-width: 991px) and (min-width: 640px) {
	.product-name {
	  font-size: 18px;
	}
	.price {
	  font-size: 18px;
	}
  
	.product-price {
	  .tax-includes {
		font-size: 13px;
	  }
	}
	.verified {
	  font-size: 13px;
	}
	.add-to {
	  .btn-solid {
		font-size: 15px;
	  }
	}
  }


  @media (min-width: 1300px){
	section .star{
		width: 100%;
		}
	
  }


@media (max-width: 640px) {
	section .star{
		width:96%;
		transform: translate(13px, 10px);
		margin: 10px;
		.review{
			width: 97%;
		}
	}
}

@media (min-width: 1300px) {
	
	
	footer{
        .footer-links {
            display: block;
        }
    }
	footer{
        .soc-icons{
            display: flex;
        }
    }
}

@media (max-width: 640px) {
	
	.slider{
		display:flex;
		width: 94%;
		transform: translate(10px, -10px);
	  .content {
		h2, h1 {
		  font-size: 18px;
		}
	  }
	}

footer{
        .soc-icons {
            display: grid !important;
			margin-left: -10px;
			
        
		.footer-col{
			justify-content: center !important;
			padding: 5px;
			margin:5px;
			min-width:136%;
			
			.social-icons1{
			max-width: 370px;
			}
		}
	}
    }
}

@media (max-width: 640px) {
	// .vistas-home-cell{
	// 	height: 953px;
	// }
	.vistas-homeimg{
		margin-top: 40px;
		display: flex;
		justify-content: center;
	}
	.specialwire-listing-price{
			font-size: 16px;
			color: #2d5986;
			font-weight: 700;
	}
	.special-listing-price{
		font-size: 12px;
		color: black;
		font-weight: 700;
	}
	.avonbanner{
		height: 30vh;
		width:100%
		}
		.dropdown{
			width: 100%;
		}
.cartscr{
				display: block;
			}
			.special-foot-email{
				margin-left: -15px;
			}
			.special-subbtn{
				color: white; 
				background-color: #986918;
				height:40px;
				width:138px !important;
			}
			.product-list-tile3{
				width: 307px;
				display: flex !important;
				justify-content: center;
				margin-left: 5px;
				color: #2d5986;
				font-size: 16px !important;
				//color: #000 !important;
				font-weight: 700 !important;
				overflow: hidden !important;
				
				text-overflow: ellipsis !important;
				height: 38px;
				text-transform: uppercase;
				// background-image: linear-gradient(
				//   -225deg,
				//   #231557 0%,
				//   #44107a 29%,
				//   #ff1361 67%,
				//   #fff800 100%
				// );
				// background-size: auto auto;
				// background-clip: border-box;
				// background-size: 200% auto;
				// color: #fff;
				// background-clip: text;
				// text-fill-color: transparent;
				// -webkit-background-clip: text;
				// -webkit-text-fill-color: transparent;
				// animation: textclip 2s linear infinite;
				display: inline-block;
					font-size: 190px;
				//text-shadow: 1px 1px 2px black, 0 0 25px blue, 0 0 5px grey;
			  }
	}
	
	@media (min-width: 640px) {
		.vistas-detail-cart {
			white-space: nowrap;
			width: -moz-fit-content;
			width: fit-content;
			position: relative;
			margin-top: 8px;
			background-color: transparent!important;
			border: 3px solid #986918!important;
			color: #091214!important;
			font-size: small;
			top: 10px!important;
		}
		.specialwire-listing-price{
			font-weight: bolder;
    		font-size: 1.0rem;
			color: #2d5986;
	}
		.product-list-tile3 {
			color: #2d5986 !important;
			font-size: 16px!important;
			font-weight: 700!important;
			overflow: hidden!important;
			display: -webkit-box!important;
			-webkit-line-clamp: 2!important;
			-webkit-box-orient: vertical!important;
			text-overflow: ellipsis!important;
			display: inline-block;
			font-size: 190px;
		}
		.dropspecial{
			width: auto;
			border-color: #986918;
			align-items: center;
			justify-content: center;
			border-radius: 10px;
		}
		.dropspecial1{
			width: 30%;
			border-color: #986918;
			align-items: center;
			justify-content: center;
			border-radius: 10px;
		}
		.special-foot-email{
			margin-left: -15px;
		}
		.special-subbtn{
			color: white; 
			background-color: #986918;
			height:40px;
			width:138px !important;
		}
		.cartscr{
			
			width: 100%;
		}
		.listdetail{
			width: 77%;
    margin-left: 7px;
		}
		.dropdown{
			width: 100%;
		}
	.avonbanner{
		margin-top: 2px;
		margin-left: 20px;
		border: 2px solid rgb(144, 140, 140);
		width: 97%;
		
		color: white;
		object-fit: cover;
		height: 130vh;
		}
	}


	@media (min-width: 640px) {
		.rounder-corner-nav .owl-theme .owl-nav .owl-prev, .rounder-corner-nav .owl-theme .owl-nav .owl-next {
			border-radius: 20px;
			width: 40px;
			height: 40px;
			// background: transparent;
			border: 2px solid black !important;
		  }
		  .productDetailImage{
			height: 700px;
		  }
		.vistas-detailimage{
			display: block;
			height: 700px;
		}
		.vistas-homeimg{
			display: flex;
			justify-content: center;
		}
		.vistas-home-cell{
			margin-top: 40px;
			display: flex;
		}
		.special-carousel{
			bottom: -25px !important;
		}
		.special-cart{
			width:50%
		  }
		.specialViewCart1{
			background-color:#2177b8f2 !important;
			color:$white !important;
			border:none !important;
			width:30%;
			margin-top: 2%;
			margin-left: -4% !important;
			margin-bottom: 20px;
		}
		.specialViewCart{
			background-color:#2177b8f2 !important;
			color:$white !important;
			border:none !important;
			width:55%;
			margin-top: 2%;
			margin-left: 22% !important;
			margin-bottom: 20px;
		}
		.vertical-line{
				display: flex;
				justify-content: center;
				
			}
			.special-listing-price{
				font-size: 14px;
				color: black;
				font-weight: 700;
			}
		.home-prod{
				
			.home-prod1{
				height: 200px;
				width: 275px;
				.prod-image{
					height: 148px;
					object-fit: contain;
					width:100% !important
				}
			}
		}
		}
		
		@media (max-width: 640px) {
			.special-carousel{
				bottom: -31px !important;
			}
			// .special-cart{
			// 	width:63% !important
			//   }
			.dropspecial{
				width: auto;
				border-color: #4890ba;
				align-items: center;
				justify-content: center;
				border-radius: 10px;
			}
			.dropspecial1{
				width: 300%;
				border-color: #4890ba;
				align-items: center;
				justify-content: center;
				border-radius: 10px;
			}
			// .detail-preview{
			// 	margin-top: -13px;
			// }
			.specialViewCart{
				background-color:#2177b8f2 !important;
				color:$white !important;
				border:none !important;
				width: 77%;
				margin-left: 8px;
    			margin-top: 12%;
				margin-bottom: 20px;
			}
			.specialViewCart1{
				background-color:#2177b8f2 !important;
				color:$white !important;
				border:none !important;
				width: 40%;
				margin-top: 2%;
				margin-bottom: 20px;
			}
		.vertical-line{
				margin-top: 20px;
			}
		.home-container4{
		
				width: 190px;
			}
			.home-prod{
				height: 140px;
				width: 195px;
				.home-prod1{
					height: 140px;
					width: 190px;
					.prod-image{
						height: 88px;
					}
					.home-prod-title{
						width: 130px;
						font-size: 15px;
					}
				}
			}

			.product-list-avon{
				margin-left: 20px;
			}
		}


		@media (max-width: 640px) {
			.row1{
					height: 300px;
					transform: translate(0px, 24px);
				}
			}

			@media (min-width: 640px) {
				.footer-col1{
					
					width: 27%;
				padding: 5px;
				margin:5px;
				
				.social-icons1{
				max-width: 370px;
				
				}
				}
				}
				
				@media (max-width: 640px) {
				.footer-col1{
						
							padding: 5px;
							margin:5px;
							
							
							.social-icons1{
							max-width: 370px;
							margin-left: 0px;
							}
							}
				}


				@media (min-width: 640px) {
					.avon-chart{
						width: -webkit-fill-available;
						
					}
				}


				@media (max-width: 640px) {
					.avon-chart{
						width: 100%;
						
					}
					.home-prod-sample{
						.home-prod1-sample{
							height: 100px;
							width: 70px;
							.prod-image{
								height: 148px;
								object-fit: contain;
								width:100% !important
							}
						}
					}
				.product-list-avon{
						margin-left: 20px;
						
					}
				}
				
				@media (min-width: 640px) {
				.home-prod-sample{
					.home-prod1-sample{
						height: 100px;
						width: 175px;
						.prod-image{
							height: 148px;
							object-fit: contain;
							width:100% !important
						}
					}
				}
				.product-list-avon{
						margin-left: 0px;
						
					}
				}

				@media (min-width: 640px) {
					.carousel-inner{
								margin-top: -10px;
								background-color: white;
							}
							.vistas_center{
								justify-content: center !important;
							}
							.vistas-addbutton{
								width: 30%
							}
					}
					
					@media (max-width: 640px) {
						.vistas-addbutton{
							width: 50%
						}
						.vistas-homeslide{
							display: flex;
							justify-content: center;
						}
						.special-checkout{
							display: flex;
							justify-content: center;
						    width: 100px;
						}
						
					.carousel-inner{
									margin-top: 20px;
								}
								.product-list-image-avonseals1{
									min-height: 190px;
									max-height: 275px;
									margin-left: 17px;
									width: 88%!important;
									border-radius: 10px;
								}
							}
					
					@media (min-width: 640px) {
						.product-list-image-vistas{
							min-height: 275px ;
							max-height: 275px ;
							width: 100% !important ;
							// width: 60% !important;
							border-radius: 10px ;
							object-fit: contain;
							
						  }
						.vistas-address{
							margin-top: 22px;
							margin-bottom:0px !important;
							width: 110%;
						}
						.vistas-number{
							height: 50px;
							display: flex;
							margin-top: 22px;
							margin-bottom:0px !important;
							justify-content: flex-start;
							width: 60%;
						}
						.vistas-footmenu{
							display: block;
							width: 125%;
						}
						.vistas-prod{
							width: 33%;
						}
						.vistas-footermenu{
							display: flex;
							justify-content: space-between;
							margin-left: 55px;
						}
						.pwrdby3{
							position: relative;top: 70px;right: 100px;
							width: 9%;
							float: right;
							margin-top: -3%;
							margin-right: 5%;
							object-fit: contain;
						
						}
						.poweredbylogo2{
							position: relative;
							top: 58px;
							width: 9%;
							float: right;
							margin-top: -3%;
							margin-right: 5%;
							object-fit: contain;
						
						}
						.product-list-image-avonseals1{
							min-height: 190px;
							max-height: 275px;
							width: 62%!important;
							border-radius: 10px;
						}
						.special-img2{
							text-align: center;
						}
									.footer-social-icons{
										display: flex;
									.footer-col1{
										
										justify-content: center !important;
									padding: 5px;
									margin-left: auto;
									.social-icons1{
									max-width: 500px;
									
									}
									}
									}
									.footer-row-three1{
										background-color: #020a12;
										height: 77px;
									}
								}
									@media (max-width: 640px) {
										.desk1{
											display: none !important;
										}
										.product-list-image-vistas{
											min-height: 119px ;
											max-height: 200px;
											width: 150%;
											height: 100%;
											}
										.product-list-description-vistas{
											padding: 10px 15px;
											margin-bottom: 10px;
											width: 106%;
										}
										.vistas-address{
											margin-bottom:0px !important;
										}
										.vistas-number{
											width: 100%;
											height: 50px;
											display: flex;
											justify-content: flex-start !important;
											margin-bottom: 0px !important;
										}
										.footer-row-one{
											justify-content: center !important;
										}
									
										.vistas-prod{
											width: 100%;
										}
										.vistas-footermenu{
											display: block;
											margin-left: 35px;
										}
										.pwrdby3{
											position: relative;
											top: 31px;
											white-space: nowrap;
											left: 120px;
											width: 20%;
											margin-top: -13%;
											margin-right: 0%;
										}
										.poweredbylogo2{
											position: relative;
											height: 70;
											top: 54px;
    										left: 206px;
											min-width: 22%;
											min-height: 20%;
											width: 16%;
											margin-top: -26%;
											margin-right: 0%;
										}
										.footer-social-icons{
											display: block;
									.footer-col1{
													justify-content: center !important;
												padding: 5px;
												margin:5px;
												
												
												.social-icons1{
												max-width: 350px;
												margin-left: 0px;
												}
												}
									}
									.social-icon3{
										width: 102%;
										font-size: 12px;
   										color: #f0f8ff;
									}
									.footer-row-three1{
										background-color: #020a12;
										height: 85px;
   										margin-top: 47px;
									}
									
								}

								@media (min-width: 640px) {
									.vistas-imgcol{
										height: 600px;
									}
									.vistasinput{
										margin-top: 5px;
									}
									.vistas-detailadd{
										margin-bottom: 20px;
										width:100%;
									}
									.vistas-homedesc{
										margin-top: 30px;
									}
									.vistas-welcomepaper{
										margin-top: 50px;
									}
									.desk1{
										display: flex;
									}
									.product-list-price3{
										font-size: 18px ;
										color: #296c8e !important;
										font-weight: 600 !important;
									}
									.vistas-footercol{
                                       max-width: 400px !important;
									}
									.product-list-description-vistas{
										padding: 10px 15px;
    									margin-bottom: 0px;
									}
									.product-list-cards-specialwires1{
										box-shadow: 0px 0px 10px 4px rgba(0,0,0,0.25);
										width: 91%;
										height: 323px;
									}
									.product-list-cards-specialwires4{
										width: 91%;
										height: 323px;
										
									}
									.special-about{
										display: flex;
										margin-bottom: 20px;
									}
									.special-dropitem{
										display: block;
										width: 100%;
										padding: 0.25rem 1.5rem;
										clear: both;
										font-weight: 400;
										color: #212529;
										text-align: center !important;
										white-space: nowrap;
										background-color: transparent;
										border: 0;
									}
									.special-dropdownmenu{
										min-width: 30% !important;
										max-width: fit-content;
									}
									.special_owner{
										margin-left: 20px !important;
									}
									// .slider2{
									// 	display:flex;
									// 	width: 89%;
									// 	transform: translate(64px, -10px);
									// }
									.product-list-image-specialwires{
										padding-top: 13px;
										min-height: 200px ;
										max-height: 200px;
										width: 79% !important;
										}
									.special-owner{
										margin-left: 20px;
									}
									.special-owner1{
										margin-left: 20px;
									}
									.product-list-cards-specialwires2:hover{
										
										box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
										.product-list-cards-specialwires1{
											
											padding: 10px;
											object-fit: contain !important;
										}
										.specialwires-description{
											box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
										
										}
										
									}
									.vistas-homehead{
										width: 99%;
									}
									.slider3 {
										width: 83%;
										margin-left: 9%;
										display: flex;
									}
									.slider4 {
										width:79%;
										margin-left: 10%;
										display: flex;
									}
									.slider5{
										width:77%;
										margin-left: 11%;
										display: flex;
									}
									.product-list-description-special{
										width: 100%;
										padding: 10px 15px;
    									margin-bottom: 10px;
									}
									.product-list-description-special1{
										
   										width: 100%;
										padding: 10px 6px;
    									margin-bottom: 10px;
									}
									.special_home_prodname{
										display: flex !important;
    									justify-content: center !important;
										width: 100%;
										
										margin-bottom: 25px;
									}
									.social-icon3{
										
										font-size: 12px;
										color: aliceblue ;
									}
									.content-title3{
												text-shadow: none!important;
												font-family: proxima-regular,"sans-serif"!important;
												color: #002252a8!important;
												font-size: 40px!important;
												padding-top: 0!important;
												margin-top: 8px!important;
												font-size: 38px!important;
												font-weight: 700!important;
												max-width: 105%!important;
												
											}
									.product-title{
										margin-left: 10%;
										width: 80%;
									}
									.slider1{
												width: 94%;
												margin-left: 0%;
												display: flex;
											}
									.chem-components{
												display: flex;
											}
									}
									@media (max-width: 640px) {
										// .button_vistas{
										// 	width:100%
										// }
										.button-62{
											margin-right: 5px;
										}
										.commonstylewidth{
											width: 92% !important;
										}
										.vistas-welcomepaper{
											margin-top: 30px;
										}
										.vistasaa{
											margin-right: 10%;
										}
										.vistas-detailadd{
											margin-bottom: 19px;
											width:96%;
										}
										.product-list-price3{
											color: #296c8e !important;
											font-weight: 600 !important;
											font-size: 14px ;
										}
										.vistas-homebackground{
											height: 417px;
										}
										.special-dropdownmenu{
											min-width: 160% !important;
											max-width: fit-content;
										}
										.special-dropitem{
											display: block;
											width: 100%;
											padding: 0.25rem 1.5rem;
											clear: both;
											font-weight: 400;
											color: #212529;
											text-align: center !important;
											white-space: nowrap;
											background-color: transparent;
											border: 0;
										}
										.vistas-button1{
											top: 60%;
											position: fixed;
											width: 45%;
										}
										.product-list-image-specialwires{
											min-height: 130px ;
											max-height: 140px ;
											width: 81% !important ;
											// width: 60% !important;
											border-radius: 10px ;
											object-fit: contain;
											
										  }
										  
										  .product-list-image-specialwires1{
											  min-height: 190px;
											  max-height: 270px;
											  width: 81%!important;
											  border-radius: 10px;
											  object-fit: contain;
										  }
										.special-form{
											margin-top: 1px !important;
										}
										.product-list-cards-specialwires2{
											display: flex;
											box-shadow: 0 0 10px 4px #00000040;
											margin-bottom: 10px;
											margin-left: 5px;
											width: 97%;
										}
										.product-list-cards-specialwires3{
											display: flex;
											box-shadow: 0 0 10px 4px #00000040;
											margin-bottom: 10px;
											margin-left: 4px;
											width: 96%;
										}
										.specialwires-description{
											width: 100%;
										}
										.product-list-cards-specialwires2:hover{
										
											box-shadow: none;
											.product-list-cards-specialwires1{
												
												padding: 10px;
												object-fit: contain !important;
											}
											.specialwires-description{
												box-shadow: none;
											
											}
											
										}
										.product-list-cards-specialwires1{
											height: 176px;
											display: flex;
											box-shadow: 0px 0px 10px 4px rgba(0,0,0,0.25);
											margin-bottom: 10px;
											margin-left: 19px;
											width: 94%;
										}
										.product-list-cards-specialwires4{
											height: 176px;
											display: flex;
											box-shadow: 0px 0px 10px 4px rgba(0,0,0,0.25);
											margin-bottom: 10px;
											margin-left: 19px;
											width: 91%;
										}
										.cartButtonkubweight{
											font-size: 9px !important;
											padding: 4px;
																  font-weight: bold;
															 
																  margin-top: 4px;
																  background-color: transparent !important;
																  border: 1px solid #2177b8f2 !important;
																  color: #091214 !important;
																  background-color: grey;
																  border-radius: 55px;
										
										  }
										.product-list-description-special1{
											margin-left: 8px;
											   width: 55%;
										
										}
										.special_home_prodname{
											display: flex !important;
    										justify-content: center !important;
											width: 85%;
											margin-bottom: 30px;
										}
									.content-title2{
											text-shadow: none!important;
												font-family: proxima-regular,"sans-serif"!important;
												color: #373230!important;
												
												padding-top: 0!important;
												margin-top: 8px!important;
												font-size: 22px!important;
												font-weight: 700!important;
												max-width: 105%!important;
										}
										.content-title3 {
											text-shadow: none!important;
											font-family: proxima-regular,"sans-serif"!important;
											color: #002252a8!important;
											padding-top: 0!important;
											margin-top: 8px!important;
											font-size: 22px!important;
											font-weight: 700!important;
											max-width: 92%!important;
										}
									.slider1{
											width: 93%;
											margin-left: 0%;
											display: flex;
										}
									.slider2{
											display:flex;
											width: 89%;
											transform: translate(7px, -10px);
										}
										.slider3 {
											width: 87%;
											margin-left: 4%;
											display: flex;
											height: 207px;
										}
										.slider4 {
											width: 86%;
											margin-left: 6%;
											display: flex;
										}
										.slider5{
											width: 86%;
											margin-left: 6%;
											display: flex;
										}
									.row3{
									  transform: translate(30px,0px);
									}
									.product-title{
										margin-left: 6%;
										width: 94%;
										transform: translate(-12px, 0px);
									}
									.product-list-description-special{
										margin-left: 0px;
									}
									.title1{
										font-size: 29px !important;
									}
									.chem-components{
										display: grid;
    									justify-content: center;
									}
									}
									.special-addtocart{
										margin-top: 30px;
										padding: 0 3rem !important;
									}
									@media (min-width: 1700px) {
										.vistas_addbutton{
										width: 300px;
										}
										}
										@media (min-width: 1400px) {
											.vistas_addbutton{
											width: 250px;
											}
											}
									.button-62 {
										background-color: white !important;
										border-color: #4e90b2 !important;
										border: 3px solid #4e90b2!important;
										margin-bottom: 20px;
										border-radius: 12px;
										color: black !important;
										cursor: pointer;
										display: inline-block;
										font-family: -apple-system,system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
										font-size: 16px;
										font-weight: 500;
										line-height: 2.5;
										outline: transparent;
										padding: 0 1rem;
										text-align: center;
										text-decoration: none;
										transition: box-shadow .2s ease-in-out;
										user-select: none;
										-webkit-user-select: none;
										touch-action: manipulation;
										white-space: nowrap;
									  }
									  .product-list-cards-specialwires2{
										box-shadow: #00000005 0 1px 3px, #1b1f2326 0 0 0 1px;
										border: none;
										border-radius: 10px;
										width: 282px;
									  }
									  .product-list-cards-specialwires3{
										border: none;
										border-radius: 10px;
										min-width: 80%;
										max-width: 100%;
									  }
									  .button-62:not([disabled]):hover {
										box-shadow: 0 0 .25rem rgba(9, 13, 116, 0.5), -.125rem -.125rem 1rem rgba(51, 142, 228, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
										
									  }
									  .sub-list3.active{
										font-weight: 700;
										color: #ffc107!important;
										text-transform: uppercase;
									}
									.specialsize{
										font-size: 12px !important;
										color: black !important;
									}
									.sub-list3
									{
										text-transform: uppercase;
										font-weight: 700;
										color: #0195da!important;
										cursor: pointer;
									}
									.dropdown-item.active, .dropdown-item:active {
										color: #fff;
										text-decoration: none;
										background-color: #0195da;
									}
									#prodName {
										font-size: 1.6rem;
										color: #2d5986 !important;
									}
									.colour-select-container{
										margin-left: 10px;
										margin-right: 10px;
										display: grid;
										justify-content: center;
										grid-template-columns: repeat(3,1fr);
									}
									.cartButtonspecialweight{
										font-size: small;
										padding: 7px;
										font-weight: bold;
										margin-top: 4px;
										background-color: transparent !important;
										border: 1px solid #2177b8f2 !important;
										color: #091214 !important;
										background-color: grey;
										border-radius: 15px;
										margin-right: 13px;
									  }
									  .cartButtonspecialweight.active{
										background-color: #0195da !important;
										color: white !important;
									  }
									  .cartButtonspecialweight:hover{
										background-color: #336699f2 !important;
									  }
									  .cartButtonspecialweight1{
										font-size: small;
										padding: 7px;
										font-weight: bold;
										margin-top: 4px;
										background-color: transparent !important;
										border: 1px solid #2177b8f2 !important;
										color: #091214 !important;
										background-color: grey;
										border-radius: 15px;
										margin-right: 13px;
										margin-bottom: 30px;
									  }
									  .cartButtonspecialweight1.active{
										background-color: #0195da !important;
										color: white !important;
									  }
									  .cartButtonspecialweight1:hover{
										background-color: #336699f2 !important;
									  }
									  .special_comp_title{
										font-size: large;
										font-weight: bold;

									  }
									  #myBtn1 {
										display: none;
										position: fixed;
										bottom: 40px;
										right: 20px;
										z-index: 99;
										font-size: 17px;
										border: none;
										outline: none;
										background-color: $pallet2;
										opacity: 0.7;
										color: $white;
										cursor: pointer;
										padding: 8px 18px;
										border-radius: 50%;
										font-weight: bold;
										box-shadow: 0 3px 16px 0 #555;
									  }
									  
									  #myBtn1:hover {
										background-color: $pallet2;
										opacity: 1;
										color: $white;
										
									  }
									  .logo-section{
										background-color: white !important;
										margin-top: 10px;
									  }
									  .logo-section1{
										background-color: white !important;
										margin-top: 10px;
									  }
									  .logo-images{
										object-fit: contain;
										width: 91px !important;
										height: 60px !important;
									}
									.product-list-tile-special{
										font-size: 15px !important;
										color: #0095da !important;
										font-weight: 700 !important;
										overflow: hidden !important;
										display: -webkit-box !important;
										-webkit-line-clamp: 2 !important;
										-webkit-box-orient: vertical !important;
										text-overflow: ellipsis !important;
										background-clip: text;
										-webkit-background-clip: text;
										display: inline-block;
									  }
									  .thumb1-special {
										display: grid;
										grid-template-columns: repeat(3, 1fr);
										
									  }
									  .special-img{
										text-align: center;
									  }
									  .special-img1{
										text-align: center;
									  }
									  .special-atc{
										font-size: small;
										font-weight: bold;
										width: 36%;
										margin-top: 4px;
										background-color: transparent !important;
										border: 3px solid #5c7fad !important;
										color: #091214 !important;
										background-color: grey;
									  }
									  .special-atc1{
										font-size: small;
										font-weight: bold;
										width: 67%;
										margin-left: 17%;
										margin-top: 4px;
										background-color: transparent !important;
										border: 3px solid #003b85 !important;
										color: #091214 !important;
										background-color: grey;
									  }
									  @media (max-width: 640px) {
										.special-owner1{
											width: 88% !important;
											margin-left: 20px;
											margin-right: 20px;
										}
										.special_owner{
											margin-left: 0px;
										}
										.special-img{
											margin-top: 19px;
											width: 50%;
										  }
										  .special-img1{
											width: 80%;
											margin-top: 25px;
										  }
										  
									  .thumb1-special{
										display: grid;
										grid-template-columns: repeat(1, 1fr);
									  }
									  .special-atc{
 
										width: 75%;
										margin-left: 10%;
										margin-top: 3px;
									  }
									  .special-atc1{
									   
										width: 53%;
									   
									  }
									}